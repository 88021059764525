import { Link, useNavigate } from "react-router-dom";
import { FOTO_SISTEMA, PLATAFORMA_SESION, TOKEN_SISTEMA } from "../../../utilities/domains/sesion";
import ApiAuth from "../../../utilities/domains/backAuth";
import { useEffect, useState } from "react";
import Usuario from "../../../models/usuario";
import ServicioPerfil from "../../../services/servicio-perfil";
import { PerfiPersonal } from "./perfilPersonal";
import { PerfilAcceso } from "./perfilAcceso";
import { PerfiFoto } from "./perfilFoto";
import { Imagen } from "../../../models/imagen";
import { PerfilDatos } from "./pefilDatos";

export const PerfilDetalle = () => {
    const [usuario, setUsuario] = useState<Usuario>(new Usuario(0, 0, 0, 0, "", "", "", "", new Date(), "", 0))
    const [imagen, setImagen] = useState<Imagen>(new Imagen(0, 0, "", "", "", "", 0, ""));
    let fotoMiniatura = localStorage.getItem(FOTO_SISTEMA) as string;
    let [cargaFinalizada, setCargaFinalizada] = useState<boolean>(false);
    const navegacion = useNavigate();

    const cerrarSesion = () => {
        localStorage.removeItem(TOKEN_SISTEMA);
        localStorage.removeItem(FOTO_SISTEMA);
        sessionStorage.removeItem(PLATAFORMA_SESION);
        navegacion("/login");
    };

    const obtenerInfoPerfil = async () => {
        const urlPerfil = ApiAuth.API_PERFIL + "/infoProfile";
        const resultado = await ServicioPerfil.peticionGET(urlPerfil);
        if (resultado == 401) {
            cerrarSesion();
        } else {
            setUsuario(resultado);
            obtenerImagen();
        }
    }

    const obtenerImagen = async () => {
        const urlPerfil = ApiAuth.API_IMAGEN_PERFIL + "/user";
        const resultado = await ServicioPerfil.peticionGET(urlPerfil);

        if (resultado == 401) {
            cerrarSesion();
        } else {
            setCargaFinalizada(true);
            setImagen(resultado);
        }
    }

    useEffect(() => {
        obtenerInfoPerfil();
    }, [])

    return (
        <div>
            <h3 className="text-capitalize  fst-italic fw-bolder">Perfil</h3>
            <nav className="" aria-label="breadcrumb">
                <ol className="breadcrumb fs-6">
                    <Link className="text-decoration-none link-info fw-normal breadcrumb-item" to="/dash">Incio</Link>
                    <li className="active breadcrumb-item" aria-current="page">Perfil</li>
                </ol>
            </nav>
            {cargaFinalizada ?
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-3">
                        <div className="card">
                            <div className="p-4 card-body">
                                <div className="text-center mt-4">
                                    <img src={imagen.base64Imagen ? 'data:image/png;base64,' + imagen.base64Imagen : 'data:image/png;base64,' + fotoMiniatura} className="rounded-circle" width="150" alt="" />
                                    <h4 className="mt-2 mb-0 card-title">{usuario.nombresUsuario}</h4>
                                    <div className="text-muted card-subtitle">{usuario.nombreRol}</div>
                                </div>
                            </div>
                            <div className="border-top p-4 card-body">
                                <div>
                                    <div className="text-muted fs-6 card-subtitle">CorreoElectronico</div>
                                    <h6 className="card-title">{usuario.correoAcceso}</h6>
                                    <div className="text-muted fs-6 mt-3 card-subtitle">Telefono</div>
                                    <h6 className="card-title">{usuario.telefonoUsuario}</h6>
                                    <div className="text-muted fs-6 mt-3 card-subtitle">Documento</div>
                                    <h6 className="card-title">{usuario.idUsuario}</h6>
                                
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-9">
                        <div className="card">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a href="#default-tab-1" data-bs-toggle="tab" className="active bg-transparent nav-link">
                                        <span className="d-sm-none">Tab 1</span>
                                        <span className="d-sm-block d-none"><i className="fa fa-user font-18 me-1"></i> Información personal</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#default-tab-2" data-bs-toggle="tab" className=" bg-transparent nav-link">
                                        <span className="d-sm-none">Tab 2</span>
                                        <span className="d-sm-block d-none"><i className="fa fa-key font-18 me-1"></i> Usuario</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#default-tab-3" data-bs-toggle="tab" className="bg-transparent nav-link">
                                        <span className="d-sm-none">Tab 3</span>
                                        <span className="d-sm-block d-none"><i className="fa fa-images font-18 me-1"></i> Foto usuario</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#default-tab-4" data-bs-toggle="tab" className="bg-transparent nav-link">
                                        <span className="d-sm-none">Tab 4</span>
                                        <span className="d-sm-block d-none"><i className="fa fa-database font-18 me-1"></i> Datos usuario</span>
                                    </a>
                                </li>

                            </ul>

                            <div className="tab-content panel rounded-0 p-3 m-0">
                                <div className="tab-pane fade active show" id="default-tab-1">
                                    <PerfiPersonal  />
                                </div>
                                <div className="tab-pane" id="default-tab-2">
                                    <PerfilAcceso  />
                                </div>
                                <div className="tab-pane" id="default-tab-3">
                                    {/* {<PerfiFoto />} */}
                                </div>
                                <div className="tab-pane" id="default-tab-4">
                                    <PerfilDatos  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                :
                <div>
                    <div className="d-flex justify-content-center align-items-end" style={{ height: " 300px" }}>
                        <div className="spinner-border fs-6 text-theme" style={{ width: "2.8rem", height: " 2.8rem" }} role="status">
                        </div>
                        <br />
                    </div>
                    <p className="lead text-center text-theme mt-1 fs-3">Cargando ...</p>
                </div>
            }
        </div>
    );
}