class Usuario {
    public idUsuario: number;
    public idRol: number;
    public idEstadocivil: number;
    public idUbicacion: number;
    public documentoUsuario: string;
    public tipoDocumentoUsuario: string;
    public nombresUsuario: string;
    public apellidosUsuario: string;
    public fechaNacimientoUsuario: Date;
    public telefonoUsuario: string;
    public estadoUsuario: number;
    public auditLogUsuario?: Object;

    public nombreRol?: string;
    public nombreEstadocivil?: string;
    public nombreUbicacion?: string;
    public correoAcceso?: string;

    constructor(id: number, idR: number, idE: number, idU: number, doc: string, tip: string, nom: string,
        apell: string, fec: Date, tel: string, est: number) {
        this.idUsuario = id;
        this.idRol = idR;
        this.idEstadocivil = idE;
        this.idUbicacion = idU;
        this.documentoUsuario = doc;
        this.tipoDocumentoUsuario = tip;
        this.nombresUsuario = nom;
        this.apellidosUsuario = apell;
        this.fechaNacimientoUsuario = fec;
        this.telefonoUsuario = tel;
        this.estadoUsuario = est;
    }

}


export default Usuario;