export class Dato {

    public idDato: number;
    public idMetadato: number;
    public idUsuario: number;
    public detalleDato: string;
    public nombreTipo?: string;
    public nombre1Metadato?: string;
    public idTipo?: number;


    constructor(idD: number, idM: number, idU: number, deta: string) {
        this.idDato = idD;
        this.idMetadato = idM;
        this.idUsuario = idU;
        this.detalleDato = deta;
        this.idTipo=0;
    }

}
