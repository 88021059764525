
import { FC, useState } from "react";

import { DatoSesion } from "../models/datoSesion";
import { propSesion } from "../models/misInterfaces";
import { TOKEN_SISTEMA } from "../utilities/domains/sesion";
import { ContextoUsuario } from "./contextoUsuario";
import { jwtDecode } from "jwt-decode";

const UsuarioSesion: FC<propSesion> = ({ children }) => {
    let usuarioCargado = new DatoSesion(0, 0, '', '', '', '');
    const actualizar = (objUsuario: DatoSesion) => { setAutenticado(objUsuario); };

    if (localStorage.getItem(TOKEN_SISTEMA)) {
        const elToken = String(localStorage.getItem(TOKEN_SISTEMA));
        try {
            const sesion: any = jwtDecode(elToken);
            usuarioCargado = new DatoSesion(
                sesion.id,
                sesion.idRol,
                sesion.correoAcceso,
                sesion.nombreRol,
                sesion.nombresUsuario,
                sesion.apellidosUsuario);
        } catch {
            console.log("Error");
        }
    }
    const [autenticado, setAutenticado] = useState<DatoSesion>(usuarioCargado);

    return (
        <ContextoUsuario.Provider value={{ autenticado, actualizar }}>
            {children}
        </ContextoUsuario.Provider>
    );
};

export default UsuarioSesion;
