import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormulario } from "../../../utilities/misHooks/useFormulario";
import Usuario from "../../../models/usuario";
import ServicioPerfil from "../../../services/servicio-perfil";
import ApiAuth from "../../../utilities/domains/backAuth";
import { crearMensaje } from "../../../utilities/funciones/mensajes";
import { Button, Form } from "react-bootstrap";
import { Ubicacion } from "../../../models/ubicacion";
import { ARREGLO_TIPO_DOCUMENTO } from "../../../utilities/domains/tipoDocumento";
import { ContextoUsuario } from "../../../seguridad/contextoUsuario";

export const PerfiPersonal = () => {

    type formHtml = React.FormEvent<HTMLFormElement>;
    const [enProceso, setEnProceso] = useState<boolean>(false);
    const [arrUbicaciones, setArrUbicaciones] = useState<Ubicacion[]>([]);
    const [arrTipos, setArrTipos] = useState<any[]>(ARREGLO_TIPO_DOCUMENTO);
    let [cargaFinalizada, setCargaFinalizada] = useState<boolean>(false);
    const navegacion = useNavigate();
    const usuarioSesion = useContext(ContextoUsuario);
    const idUsu = usuarioSesion?.autenticado.id;

    let { idUsuario, nombresUsuario, apellidosUsuario, tipoDocumentoUsuario, documentoUsuario, telefonoUsuario, idUbicacion, dobleEnlace, objeto } = useFormulario<Usuario>(new Usuario(0, 0, 0, 0, "", "", "", "", new Date(), "", 0));


    /* const obtenerUsuarioPerfil = async () => {
        const resultado = await ServicioPerfil.peticionGET(ApiAuth.API_PERFIL + "/infoProfile");

        if (resultado) {
            objeto.idUsuario = resultado.idUsuario;
            objeto.idRol = resultado.idRol;
            objeto.idEstadocivil = resultado.idEstadocivil;
            objeto.nombresUsuario = resultado.nombresUsuario;
            objeto.apellidosUsuario = resultado.apellidosUsuario;
            objeto.documentoUsuario = resultado.documentoUsuario;
            objeto.fechaNacimientoUsuario = resultado.fechaNacimientoUsuario;
            objeto.auditLogUsuario = resultado.auditLogUsuario;
            objeto.idUbicacion = resultado.idUbicacion;
            objeto.tipoDocumentoUsuario = resultado.tipoDocumentoUsuario;
            objeto.telefonoUsuario = resultado.telefonoUsuario;
            obtenerUbicaciones();
        }
        return resultado;
    }; */
    const obtenerUbicaciones = async () => {
        const resultado = await ServicioPerfil.peticionGET(ApiAuth.API_UBICACION + "/all");
        if (resultado) {
            setCargaFinalizada(true);
            setArrUbicaciones(resultado);
            arrUbicaciones.shift();

        }
        return resultado;
    };

    const enviarFormulario = async (frm: formHtml) => {
        frm.preventDefault();
        setEnProceso(true);
        const formulario = frm.currentTarget;
        formulario.classList.add("was-validated");

        if (formulario.checkValidity() === false) {
            frm.preventDefault();
            frm.stopPropagation();
        } else {
            const resultado = await ServicioPerfil.peticionPUT(ApiAuth.API_PERFIL + "/update/" + idUsuario, objeto);
            if (resultado) {
                setEnProceso(false);
                crearMensaje("success", "Usuario actualizado con exito");
            } else {
                crearMensaje("error", "Fallo al actualizar la información");
            }
        }
    };

    useEffect(() => {
        const obtenerUsuarioPerfil = async () => {
            const resultado = await ServicioPerfil.peticionGET(ApiAuth.API_PERFIL + "/infoProfile");
    
            if (resultado) {
                objeto.idUsuario = resultado.idUsuario;
                objeto.idRol = resultado.idRol;
                objeto.idEstadocivil = resultado.idEstadocivil;
                objeto.nombresUsuario = resultado.nombresUsuario;
                objeto.apellidosUsuario = resultado.apellidosUsuario;
                objeto.documentoUsuario = resultado.documentoUsuario;
                objeto.fechaNacimientoUsuario = resultado.fechaNacimientoUsuario;
                objeto.auditLogUsuario = resultado.auditLogUsuario;
                objeto.idUbicacion = resultado.idUbicacion;
                objeto.tipoDocumentoUsuario = resultado.tipoDocumentoUsuario;
                objeto.telefonoUsuario = resultado.telefonoUsuario;
                obtenerUbicaciones();
            }
            return resultado;
        };
        obtenerUsuarioPerfil();
    }, [ApiAuth.API_PERFIL + "/infoProfile"]);

    return (
        <div>
            {cargaFinalizada ?
                <div className="m-4 card rounded-2">
                    <div className="bg-light card-body"><h4 className="mb-0 card-title">Información Personal</h4></div>
                    <div className="card-body">
                        <Form validated={enProceso} onSubmit={enviarFormulario}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="nombresUsuario">
                                        <Form.Label className="form-label">Nombres</Form.Label>
                                        <Form.Control type="text"
                                            className="form-control"
                                            required
                                            name="nombresUsuario"
                                            value={nombresUsuario}
                                            onChange={dobleEnlace}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="apellidosUsuario">
                                        <Form.Label className="form-label">Apellidos</Form.Label>
                                        <Form.Control type="text"
                                            className="form-control"
                                            required
                                            name="apellidosUsuario"
                                            value={apellidosUsuario}
                                            onChange={dobleEnlace}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="tipoDocumentoUsuario">
                                        <Form.Label className="form-label">
                                            <span className="rojito">*</span> Tipo Documento
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            name="tipoDocumentoUsuario"
                                            value={tipoDocumentoUsuario}
                                            onChange={dobleEnlace}
                                        >
                                            {
                                                arrTipos.map((tipos, indice) => (
                                                    tipos.codigo == "88" ?
                                                        <option key={indice} value={tipos.codigo} disabled>{
                                                            tipos.tipo
                                                        }</option>
                                                        :
                                                        <option key={indice} value={tipos.codigo}>{
                                                            tipos.tipo
                                                        }</option>
                                                ))
                                            }

                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="documentoUsuario">
                                        <Form.Label className="form-label">Documento</Form.Label>
                                        <Form.Control type="text"
                                            className="form-control"
                                            required
                                            name="documentoUsuario"
                                            value={documentoUsuario}
                                            onChange={dobleEnlace}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="telefonoUsuario">
                                        <Form.Label className="form-label">Telefono</Form.Label>
                                        <Form.Control type="text"
                                            className="form-control"
                                            required
                                            name="telefonoUsuario"
                                            value={telefonoUsuario}
                                            onChange={dobleEnlace}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="idUbicacion">
                                        <Form.Label className="form-label">
                                            Ubicación
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            name="idUbicacion"
                                            value={idUbicacion}
                                            onChange={dobleEnlace}
                                        >
                                            <option value={0} disabled> Seleccione Departamento</option>
                                            {
                                                arrUbicaciones.map((ubicacion, indice) => (
                                                    <option key={indice} value={ubicacion.idUbicacion}>{
                                                        ubicacion.nombreUbicacion
                                                    }</option>
                                                ))
                                            }

                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="border-top gap-2 d-flex align-items-center card-body">
                                    <Button type="submit" className="btn btn-success btn btn-secondary">Actualizar</Button>
                                    <Button type="button" className="btn btn-dark ml-2 btn btn-secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navegacion("/dash")
                                        }}
                                    >Cancelar</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                :
                <div>
                    <div className="d-flex justify-content-center align-items-end" style={{ height: " 150" }}>
                        <div className="spinner-border fs-6 text-theme" style={{ width: "2.8rem", height: " 2.8rem" }} role="status">
                        </div>
                        <br />
                    </div>
                    <p className="lead text-center text-theme mt-1 fs-3">Cargando ...</p>
                </div>
            }
        </div>
    )
}