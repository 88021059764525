import {
    Navbar,
    Collapse,
    Nav,
    NavItem,
    NavbarBrand,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Button,
} from "reactstrap";
import { ReactComponent as LogoWhite } from "../../assets/images/logos/adminprowhite.svg";
import { ReactComponent as LogoDark } from "../../assets/images/logos/adminpro.svg";
import { MouseEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DatoSesion } from "../models/datoSesion";
import { jwtDecode } from "jwt-decode";
import { FOTO_SISTEMA, PLATAFORMA_SESION, SESION, TOKEN_SISTEMA } from "../utilities/domains/sesion";

export const MenuSuperior = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navegacion = useNavigate();

    let objTmp: DatoSesion = jwtDecode(localStorage.getItem(TOKEN_SISTEMA) as string);
    let fotoMiniatura = localStorage.getItem(FOTO_SISTEMA) as string;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const Handletoggle = () => {
        setIsOpen(!isOpen);
    };
    const showMobilemenu = () => {
        document.getElementById("sidebarArea")?.classList.toggle("showSidebar");
    };


    const cerrarSesion = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        localStorage.removeItem(TOKEN_SISTEMA);
        localStorage.removeItem(FOTO_SISTEMA);
        localStorage.removeItem(PLATAFORMA_SESION);
        sessionStorage.removeItem(SESION);
        navegacion("/login");
    };

    return (
        <Navbar color="white" light expand="md" className="fix-header">
            <div className="d-flex align-items-center">
                <NavbarBrand href="/">
                    <strong className="text-primary"> Cliente 1</strong>
                </NavbarBrand>
                <Button
                    color="secondary"
                    className=" d-md-none"
                    onClick={() => showMobilemenu()}
                >
                    <i className="bi bi-list"></i>
                </Button>
            </div>
            <div className="hstack gap-2">
                <Button
                    color="secondary"
                    size="sm"
                    className="d-sm-block d-md-none"
                    onClick={Handletoggle}
                >
                    {isOpen ? (
                        <i className="bi bi-x"></i>
                    ) : (
                        <i className="bi bi-three-dots-vertical"></i>
                    )}
                </Button>
            </div>

            <Collapse navbar isOpen={isOpen}>
                <Nav className="navbar-nav me-auto" navbar />
                <span className="d-flex justify-content-center">

                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle color="transparent">
                            <span style={{ fontSize: "12px" }}>{objTmp.correoAcceso}</span>
                            <img
                                src={'data:image/png;base64,' + fotoMiniatura}
                                alt="profile"
                                className="rounded-circle"
                                width="30"
                            ></img>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>{objTmp.nombresUsuario}</DropdownItem>
                            <Link to="/dash/profile" className="text-decoration-none"><DropdownItem>Perfil </DropdownItem></Link>
                            <DropdownItem>Mensajes</DropdownItem>
                            <DropdownItem onClick={cerrarSesion}>Cerrar Sesión</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </span>
            </Collapse>
        </Navbar >
    );
}