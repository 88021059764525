import { useContext, useEffect, useState } from "react";
import ServicioPerfil from "../../../services/servicio-perfil";
import ApiAuth from "../../../utilities/domains/backAuth";
import { useNavigate } from "react-router-dom";
import { useFormulario } from "../../../utilities/misHooks/useFormulario";
import { Dato } from "../../../models/dato";
import { Button, Form, Modal } from "react-bootstrap";
import { crearMensaje } from "../../../utilities/funciones/mensajes";
import { ContextoUsuario } from "../../../seguridad/contextoUsuario";
import { Metadato } from "../../../models/metadato";
import { Tipo } from "../../../models/tipo";


export const PerfilDatos = () => {

    type formHtml = React.FormEvent<HTMLFormElement>;
    const [enProceso, setEnProceso] = useState<boolean>(false);
    const navegacion = useNavigate();
    const usuarioSesion = useContext(ContextoUsuario);
    const idUsu: any = usuarioSesion?.autenticado.id;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    let [cargaFinalizada, setCargaFinalizada] = useState<boolean>(false);
    const [arrTipos, setArrTipos] = useState<Tipo[]>([]);
    const [arrDatos, setArrDatos] = useState<Dato[]>([]);
    const [arrMetadatos, setArrMetadatos] = useState<Metadato[]>([]);
    const [datoSeleccionado, setDatoSeleccionado] = useState<Dato>(new Dato(0, 0, 0, ""))
    let { idUsuario, idDato, idTipo, idMetadato, detalleDato, dobleEnlace, objeto } = useFormulario<Dato>(new Dato(0, 0, idUsu, ""));

    const obtenerDatos = async () => {
        const resultado = await ServicioPerfil.peticionGET(ApiAuth.API_DATOS + "/all");
        if (resultado) {
            setArrDatos(resultado);
            obtenerTipos();
            setCargaFinalizada(true);
        }
        return resultado;
    };

    const obtenerTipos = async () => {
        const resultado = await ServicioPerfil.peticionGET(ApiAuth.API_TIPO + "/all");
        setArrTipos(resultado);
        return resultado;
    };

    const obtenerMetaDatos = async (codTipo: number) => {
        const resultado = await ServicioPerfil.peticionGET(ApiAuth.API_METADATO + "/type/" + codTipo);
        setArrMetadatos(resultado);
        return resultado;
    };

    const cambiarComboTipo = (codTipo: string) => {
        obtenerMetaDatos(Number(codTipo));
    }

    const enviarFormulario = async (frm: formHtml) => {
        frm.preventDefault();
        setEnProceso(true);
        const formulario = frm.currentTarget;
        formulario.classList.add("was-validated");

        if (formulario.checkValidity() === false) {
            frm.preventDefault();
            frm.stopPropagation();
        } else {
            const resultado = await ServicioPerfil.peticionPOST(ApiAuth.API_DATOS + "/add", objeto);
            if (resultado.respuesta) {
                setEnProceso(false);
                crearMensaje("success", "Datos usuario registrado con exito");
            } else {
                crearMensaje("error", "Fallo al registrar la información");
            }
            limpiarCajas(formulario);
            obtenerDatos();
        }
    };

    const limpiarCajas = (formulario: HTMLFormElement) => {
        formulario.reset();
        objeto.detalleDato = "";
        objeto.idTipo = 0;
        objeto.idMetadato = 0;
        formulario.detalleDato.value = "";
        formulario.classList.remove("was-validated");

    };

    const eliminarDatos = async (idDato: number) => {
        const url = ApiAuth.API_DATOS + "/delete/" + idDato;
        const resultado = await ServicioPerfil.peticionDELETE(url);
        if (resultado.respuesta) {
            crearMensaje("success", "Departamento eliminado con exíto");
        } else {
            crearMensaje("error", "Fallo al eliminar el departamento");
        }
        obtenerDatos();
        return resultado;
    };


    useEffect(() => {
        obtenerDatos();
    }, [])
    return (
        <div>
            {cargaFinalizada ?
                <div className="m-4 card rounded-2">
                    <div className="bg-light card-body"><h4 className="mb-0 card-title">Tipos Datos</h4></div>
                    <div className="row">
                        <div className="col-md-7">
                            {arrDatos.length != 0 ?
                                <div className="my-5">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered table-striped table-sm rounded-3">
                                            <thead className="bg-dark rounded-3">
                                                <tr>
                                                    <th style={{ width: "5%" }}>#</th>
                                                    <th style={{ width: "35%" }}>Tipo</th>
                                                    <th style={{ width: "20%" }}>Metadato</th>
                                                    <th style={{ width: "20%" }}>Detalle</th>
                                                    <th style={{ width: "10%" }}>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody className=" table-sm">
                                                {
                                                    arrDatos.map((objDato, indice) => (
                                                        <tr key={indice}>
                                                            <td scope="row">{indice + 1}</td>
                                                            <td>{objDato.nombreTipo}</td>
                                                            <td>{objDato.nombre1Metadato}</td>
                                                            <td>{objDato.detalleDato}</td>
                                                            <td className="text-center">
                                                                <a onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShow(true);
                                                                    setDatoSeleccionado(objDato);
                                                                }}>
                                                                    <i className="fa fa-trash-alt  fs-5 text-danger"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div> : <div>
                                    <div className=" m-2 card border-info text-white mb-10px col-auto bg-info">
                                        <div className="d-flex justify-content-center ">
                                            <div className="card-body">
                                                <blockquote className="blockquote fw-semibold">
                                                    <p className="fs-4"> No tiene datos registrados</p>
                                                </blockquote>
                                                <p>Registre la información de usuario en el formulario</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>
                        <div className="col-md-5">
                            <div className="card-body">
                                <Form validated={enProceso} onSubmit={enviarFormulario}>

                                    <Form.Group className="mb-3" controlId="idTipo">
                                        <Form.Label className="form-label">
                                            Información
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            name="idTipo"
                                            id="idTipo"
                                            value={idTipo}
                                            onChange={(e) => {
                                                cambiarComboTipo(e.target?.value);
                                                dobleEnlace(e);
                                            }}
                                        >
                                            <option value={0} disabled>Seleccione opción</option>
                                            {
                                                arrTipos.map((tipos, indice) => (
                                                    <option key={indice} value={tipos.idTipo}>{
                                                        tipos.nombreTipo
                                                    }</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="idMetadato">
                                        <Form.Label className="form-label">
                                            Tipo información
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            name="idMetadato"
                                            value={idMetadato}
                                            onChange={dobleEnlace}
                                        >
                                            <option value={0} disabled defaultValue={0}>Seleccione Tipo</option>
                                            {
                                                arrMetadatos.map((metadato, indice) => (
                                                    <option key={indice} value={metadato.idMetadato}>{
                                                        metadato.nombre1Metadato
                                                    }</option>
                                                ))
                                            }

                                        </Form.Select>
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="detalleDato">
                                        <Form.Label className="form-label">Detalle</Form.Label>
                                        <Form.Control type="text"
                                            className="form-control"
                                            required
                                            name="detalleDato"
                                            value={detalleDato}
                                            onChange={dobleEnlace}
                                        />
                                    </Form.Group>

                                    <div className="border-top gap-2 d-flex align-items-center card-body">
                                        <Button type="submit" className="btn btn-success btn btn-secondary">Registrar</Button>
                                        <Button type="button" className="btn btn-dark ml-2 btn btn-secondary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navegacion("/dash")
                                            }}
                                        >Cancelar</Button>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                    {/* INICIO:ventanita */}
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton className="bg-dark text-white">
                            <Modal.Title>Datos Usuario</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            ¿ Estás seguro de eliminar el registro
                            <strong> {datoSeleccionado.detalleDato}</strong> ?

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={(e) => {
                                eliminarDatos(datoSeleccionado.idDato);
                                setShow(false)
                            }}>Eliminelo</Button>

                            <Button variant="secondary" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* FIN:ventanita */}
                </div >
                :
                <div>
                    <div className="d-flex justify-content-center align-items-end" style={{ height: " 150" }}>
                        <div className="spinner-border fs-6 text-theme" style={{ width: "2.8rem", height: " 2.8rem" }} role="status">
                        </div>
                        <br />
                    </div>
                    <p className="lead text-center text-theme mt-1 fs-3">Cargando ...</p>
                </div>
            }
        </div >
    );
}