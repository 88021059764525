const ApiAuth = {
    /* URL: "http://localhost:3434/", */
    URL: "https://backauth.poporro.com/",

    INICIAR_SESION: "api/public/access/signin",
    UUID_PLATAFORMA: "api/public/access/key",
    API_UUID: 'api/private/inclock/getuuid',

    API_PERFIL: 'api/private/profile',
    API_TIPO: 'api/private/type',
    API_DATOS: 'api/private/data',
    API_USUARIO: 'api/private/user',
    API_IMAGEN: 'api/private/image',
    API_UBICACION: 'api/private/location',
    API_IMAGEN_PERFIL: 'api/private/imageProfile',
    API_METADATO: 'api/private/metadata',
    API_ACCESO_PRIVADO: 'api/private/access'



};

export default ApiAuth;
