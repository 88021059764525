export class DatoSesion {

    public id: number;
    public idRol: number;
    public nombreRol: string;
    public correoAcceso: string;
    public nombresUsuario: string;
    public apellidosUsuario: string;

    public uuidAcceso?: string;
    //public nombrePrivadoImagen?: string;

    constructor(codU: number, idR: number, nomR: string, corA: string, nomU: string, apeU: string) {
        this.id = codU;
        this.idRol = idR;
        this.nombreRol = nomR;
        this.correoAcceso = corA;
        this.nombresUsuario = nomU;
        this.apellidosUsuario = apeU;
    }

}
