import { jwtDecode } from "jwt-decode";
import { Outlet, Navigate } from "react-router-dom";
import { TOKEN_SISTEMA } from "../utilities/domains/sesion";


type propsVigilante = { children?: any };

export const Vigilante = ({ children }: propsVigilante) => {
    if (localStorage.getItem(TOKEN_SISTEMA)) {
        const token = String(localStorage.getItem(TOKEN_SISTEMA));
        try {
            jwtDecode(token);
        } catch {
            return <Navigate to="/login" />
        }
    } else {
        return <Navigate to="/login" />;
    }
    return children ? children : <Outlet />;

}