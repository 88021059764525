export const ARREGLO_TIPO_DOCUMENTO: Array<any> = [
    { codigo: "88", tipo: 'Seleccione el Tipo de Documento' },
    { codigo: "11", tipo: 'REGISTRO CIVIL' },
    { codigo: "12", tipo: 'TARJETA DE IDENTIDAD' },
    { codigo: "13", tipo: 'CÉDULA DE CIUDADANÍA' },
    { codigo: "21", tipo: 'TARJETA DE EXTRANJERÍA' },
    { codigo: "22", tipo: 'CÉDULA DE EXTRANJERÍA' },
    { codigo: "31", tipo: 'NIT' },
    { codigo: "41", tipo: 'PASAPORTE' },
    { codigo: "42", tipo: 'DOCUMENTO DE IDENTIFICACIÓN EXTRANJERO' },
    { codigo: "47", tipo: 'PEP' },
    { codigo: "50", tipo: 'NIT DE OTRO PAIS' },
    { codigo: "91", tipo: 'NUIP *' },
];
