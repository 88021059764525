import { useContext, useEffect, useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Acceso from "../../../models/acceso";
import { useFormulario } from "../../../utilities/misHooks/useFormulario";
import ServicioPerfil from "../../../services/servicio-perfil";
import ApiAuth from "../../../utilities/domains/backAuth";
import { crearMensaje } from "../../../utilities/funciones/mensajes";
import { ContextoUsuario } from "../../../seguridad/contextoUsuario";
import jsSHA from "jssha";

export const PerfilAcceso = () => {

    type formHtml = React.FormEvent<HTMLFormElement>;
    const [enProceso, setEnProceso] = useState<boolean>(false);
    const [cambiarClave, setCambiarClave] = useState<boolean>(false);
    let [cargaFinalizada, setCargaFinalizada] = useState<boolean>(false);
    const navegacion = useNavigate();

    const usuarioSesion = useContext(ContextoUsuario);
    const idUsu: any = usuarioSesion?.autenticado.id;

    let { correoAcceso, claveAcceso, reClaveAcceso, dobleEnlace, objeto } = useFormulario<Acceso>(new Acceso(idUsu, "", ""));


    const enviarFormulario = async (frm: formHtml) => {
        frm.preventDefault();
        setEnProceso(true);
        const formulario = frm.currentTarget;
        formulario.classList.add("was-validated");

        if (formulario.checkValidity() === false) {
            frm.preventDefault();
            frm.stopPropagation();
        } else {
            let resultado = '';
            if (cambiarClave) {
                let nuevoAcceso = new Acceso(objeto.idUsuario, objeto.correoAcceso, "");
                let objSha512 = new jsSHA("SHA-512", "TEXT", { encoding: "UTF8" });
                let cifrado = objSha512.update(objeto.claveAcceso).getHash('HEX');
                nuevoAcceso.claveAcceso = cifrado;
                objeto.reClaveAcceso = cifrado;
                objeto.claveAcceso = cifrado;
                resultado = await ServicioPerfil.peticionPUT(ApiAuth.API_ACCESO_PRIVADO + "/update/" + cambiarClave, nuevoAcceso);
            } else {
                resultado = await ServicioPerfil.peticionPUT(ApiAuth.API_ACCESO_PRIVADO + "/update/" + cambiarClave, objeto);
            }
            if (resultado) {
                setEnProceso(false);
                crearMensaje("success", "Usuario actualizado con exito");
            } else {
                crearMensaje("error", "Fallo al actualizar la información");
            }
        }
    };

    const validarLLavesUnicas = (llaveUnica: string): string => {
        if (llaveUnica.includes('PX_')) {
            llaveUnica = '';
        }
        return llaveUnica;
    }

    const obtenerAcceso = async () => {
        const resultado = await ServicioPerfil.peticionGET(ApiAuth.API_ACCESO_PRIVADO + "/info/" + idUsu);
        if (resultado) {
            objeto.idUsuario = resultado.idUsuario;
            if (validarLLavesUnicas(resultado.correoAcceso) == '') {
                objeto.correoAcceso = '';
                setCambiarClave(true);
            } else {
                objeto.correoAcceso = resultado.correoAcceso;
                objeto.claveAcceso= resultado.claveAcceso;
            }
            setCargaFinalizada(true);

        }
        return resultado;
    };

    useEffect(() => {
        obtenerAcceso();
    }, [])

    return (
        <div>
            {cargaFinalizada ?
                <div className="m-4 card rounded-2">
                    <div className="bg-light card-body"><h4 className="mb-0 card-title">Credenciales de Usuario</h4></div>
                    <div className="card-body">
                        <Form validated={enProceso} onSubmit={enviarFormulario}>

                            <Form.Group className="mb-3" controlId="correoAcceso">
                                <Form.Label className="form-label">Nombres</Form.Label>
                                <Form.Control type="email"
                                    className="form-control"
                                    required
                                    name="correoAcceso"
                                    value={correoAcceso}
                                    onChange={dobleEnlace}
                                />
                            </Form.Group>
                            <FormGroup className="form-check form-switch mb-3" controlId="cargarClave">
                                <Form.Check name="cargarClave" checked={cambiarClave}
                                    onChange={(e) => {
                                        setCambiarClave(cambiarClave => !cambiarClave)
                                    }} />
                                <Form.Label className="form-check-label">&nbsp;Cambiar Contraseña</Form.Label>
                            </FormGroup>
                            {cambiarClave ? <div>
                                <Form.Group className="mb-3" controlId="claveAcceso">
                                    <Form.Label className="form-label">Contraseña</Form.Label>
                                    <Form.Control type="password"
                                        className="form-control"
                                        required
                                        name="claveAcceso"
                                        value={claveAcceso}
                                        onChange={dobleEnlace}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="reClaveAcceso">
                                    <Form.Label className="form-label">
                                        <span className="rojito">*</span>Confirmar contraseña
                                    </Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        required
                                        type="password"
                                        name="reClaveAcceso"
                                        value={reClaveAcceso}
                                        pattern={claveAcceso} />
                                    <Form.Control.Feedback type="invalid">
                                        Contraseñas no coinciden
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </div> : ''}
                            <div className="border-top gap-2 d-flex align-items-center card-body">
                                <Button type="submit" className="btn btn-success btn btn-secondary">Actualizar</Button>
                                <Button type="button" className="btn btn-dark ml-2 btn btn-secondary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navegacion("/dash")
                                    }}
                                >Cancelar</Button>

                            </div>
                        </Form>
                    </div>
                </div>
                :
                <div>
                    <div className="d-flex justify-content-center align-items-end" style={{ height: " 150" }}>
                        <div className="spinner-border fs-6 text-theme" style={{ width: "2.8rem", height: " 2.8rem" }} role="status">
                        </div>
                        <br />
                    </div>
                    <p className="lead text-center text-theme mt-1 fs-3">Cargando ...</p>
                </div>
            }
        </div>
    )
}