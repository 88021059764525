import Acceso from "../models/acceso";
import ApiAuth from "../utilities/domains/backAuth";
import { PLATAFORMA_SESION } from "../utilities/domains/sesion";

class ServicioAcceso {

    public static async iniciarSesion(objAcceso: Acceso) {
        const llave = localStorage.getItem(PLATAFORMA_SESION);
        const datosEnviar = {
            method: "POST",
            body: JSON.stringify({ "acceso": objAcceso, "key": llave }),
            headers: { "Content-Type": "application/json; chatset=UTF-8", "Access-Control-Allow-Origin": "*" }
        }
        const url = ApiAuth.URL + ApiAuth.INICIAR_SESION;

        const respuesta = fetch(url, datosEnviar)
            .then((respuesta) => {
                if (respuesta.status > 400) {
                    return respuesta.status;
                } else {
                    return respuesta.json();
                }
            })
            .then((datos) => { return datos; })
            .catch((miError) => { return miError; });
        return respuesta;
    }
    
    public static async obtenerLlave() {
        const datosEnviar = {
            method: "GET",
            headers: { "Content-Type": "application/json; chatset=UTF-8", "Access-Control-Allow-Origin": "*" }
        }
        const url = ApiAuth.URL + ApiAuth.UUID_PLATAFORMA;

        const respuesta = fetch(url, datosEnviar)
            .then((respuesta) => {
                if (respuesta.status > 400) {
                    return respuesta.status;
                } else {
                    return respuesta.json();
                }
            })
            .then((datos) => { return datos; })
            .catch((miError) => { return miError; });
        return respuesta;
    }


}

export default ServicioAcceso;