export class Imagen {

    public idImagen: number;
    public idUsuario: number;
    public nombrePublicoImagen: string;
    public nombrePrivadoImagen: string;
    public tipoImagen: string;
    public tamanioImagen: string;
    public favoritaImagen: number;
    public base64Imagen: string;


    constructor(idI: number, idU: number, nomPu: string, nomPri: string, tipo: string, tama: string, fav: number, base64:string) {
        this.idImagen = idI;
        this.idUsuario = idU;
        this.nombrePublicoImagen = nomPu;
        this.nombrePrivadoImagen = nomPri;
        this.tipoImagen = tipo;
        this.tamanioImagen = tama;
        this.favoritaImagen = fav;
        this.base64Imagen = base64;
    }

}